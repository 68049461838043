<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Clicks Report
        </h2>
        <div class="card">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-3">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>View</h5>
                            <div class="wrap">
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' :dataSource='views' :mode='true' v-model="view" placeholder='View' :change='reset'></ejs-dropdownlist>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>From</h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.start" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>To</h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.end" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Domain</h5>
                            <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper" style="width: 100%;">
                              <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Search" v-model="filters.domain" />
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3">
                        <div class="control-styles" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>State</h5>
                            <ejs-dropdownlist cssClass="e-outline" id='sample-list3' :dataSource='states' :fields='fields' :mode='true' v-model="queryR.state" placeholder='State' :allowFiltering="true" :change='loadCities'></ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="add">
                                <span class="e-btn-content">Add</span>
                            </button>
                        </div>
                    </div>
                    <div v-if="view === 'City'" class="col-md-12">
                        <div class="row">
                            <div v-for="(city, index) in cities" class="col-sm-2" :key="index">
                                <div class="radio">
                                    <input type="checkbox" :id="city.city_name" :value="city.city_name" v-model="city_select">
                                    <label class="ml-2" :for="city.city_name">
                                        {{city.city_name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-12 col-md-12">
                <ejs-listbox v-if="view === 'City'" cssClass="e-horizontal-listbox" ref="list" :dataSource='selected' :itemTemplate="itemtemplate" :change="removeCity"></ejs-listbox>
                <ejs-listbox v-else cssClass="e-horizontal-listbox" ref="list" :dataSource='selected' :itemTemplate="itemtemplatest" :change="removeState"></ejs-listbox>
            </div>
        </div>
        <ejs-grid ref="grid" :dataSource="data" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :query="query"  :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings">
            <e-columns>
                <e-column field="domain" headerText="Domain" text-Align="Left"></e-column>
                <e-column field="country" headerText="Country" text-Align="Left" width="100"></e-column>
                <e-column field="state" headerText="State" text-Align="Left" width="100"></e-column>
                <e-column v-if="view === 'City'" field="city" headerText="City" text-Align="Left"></e-column>
                <e-column field="tos" headerText="Avg TOS (s)" text-Align="Left" format="N"></e-column>
                <e-column field="shown" headerText="Shown" text-Align="Left" format="N"></e-column>
                <e-column field="capped" headerText="Capped" text-Align="Left" format="N"></e-column>
                <e-column field="bounce" headerText="Bounce" text-Align="Left" format="N"></e-column>
                <e-column field="clicks" headerText="Total Clicks" text-Align="Left"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, ListBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";

//import swal from "sweetalert";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar} from '@syncfusion/ej2-vue-grids';
import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(ListBoxPlugin);

export default {
    name: "clicks",
    props: ["user"],
    data: function () {
        return {
            fields: {text: 'name', value: 'code'},
            city_fields: {text: 'city_name'},
            cities: [],
            states: [],
            city_select: [],
            selected: [],
            views: [
                "City",
                "State"
            ],
            view: "City",
            filters: {
                view: "City",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                domain: ""
            },
            temp: {
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                domain: ""
            },
            queryR: {
                state: null,
                city: null
            },
            data: null,
            state: "",
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            form: { from: "", to: "", view: "", sorting: { date: -1 } },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
            itemtemplate: "<div class='list-wrapper'><span class='text'>${state} - </span><span class='text'>${text}</span><span style='margin-left: 11px;' class='e-icons e-delete'></span></div>",
            itemtemplatest: "<div class='list-wrapper'><span class='text'>${text}<span style='margin-left: 11px;' class='e-icons e-delete'></span></div>",
        };
    },
    computed: {
        selected_cities() {
            return this.selected.map((city) => `${city.text}-${city.state}`)
        },
        query() {
            let filters = Object.assign({}, this.temp);
            return new Query().addParams('filters', JSON.stringify(filters));
        },
    },
    created: function () {
        this.loadStates();
        this.$root.preloader = false;
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        reset(){
            this.queryR.state = null;
            this.queryR.city = null;
            this.selected = [];
            this.city_select = [];
            this.$forceUpdate();
        },
        filter(){
            this.$nextTick(() => {
                if (!this.data) {
                    this.data = new DataManager({
                        url: `${this.$root.serverUrl}/admin/clicks`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers: [{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                }
                let filters = Object.assign({}, this.filters);
                filters.city = Object.assign([], this.selected_cities);
                if (this.domain) filters.domain = this.domain;
                if (this.view) filters.view = this.view;
                this.temp = filters;
                console.log(filters)
            })
        },
        add() {
            if(this.view === "City") {
                if (this.city_select.length && this.queryR.state) {
                    for (let i = 0; i < this.city_select.length; i++) {
                        this.selected.push({text: this.city_select[i], state: this.queryR.state});
                    }

                    this.$forceUpdate();
                    this.$refs.list.ej2Instances.dataSource = [...this.selected];
                    this.queryR.state = null;
                    this.queryR.city = null;
                    this.city_select = []
                    this.$forceUpdate();
                }
            } else {
                if (this.queryR.state) {
                    this.selected.push({text: this.queryR.state});
                    this.$forceUpdate();
                    this.$refs.list.ej2Instances.dataSource = [...this.selected];
                    this.queryR.state = null;
                    this.$forceUpdate();
                }
            }
        },
        removeCity(args) {
            swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;
                this.selected = this.selected.filter(function(e) { return e.text !== args.value[0] })
            });
        },
        removeState(args) {
            swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;
                this.selected = this.selected.filter(function(e) { return e.text !== args.value[0] })
            });
        },
        onSelect(arg) {
          this.selected.push({text: arg.value, state: this.state});
          this.$forceUpdate();
          setTimeout(() => {
              this.$refs.list.ej2Instances.dataSource = [...this.selected];
          }, 3000)
        },
        loadStates() {
            let request = {filters: {country_iso_code: 'US'}};
            return axios
                .get(`${this.$root.serverUrl}/admin/geos/state`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.states = resp.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        loadCities(args) {
            let request = {filters: {country_iso_code: 'US', subdivision_1_iso_code: args.value}};
            return axios
                .get(`${this.$root.serverUrl}/admin/geos/city`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.cities = resp.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        toolbarClick: function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getDate: () => {
            let date = '';
            date += ((new Date()).getMonth().toString()) + '/' + ((new Date()).getDate().toString());
            return date += '/' + ((new Date()).getFullYear().toString());
        },
        getExcelExportProperties: function() {
            return {
                fileName: "qso_export.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "qso_export.pdf"
            };
        },

        windowListener: function (e) {
            if (e.keyCode === 13 && !this.processing) {
                // this.load();
            }
        },
        convertHMS: (value) => {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
            let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
        },
        serialize: function(obj) {
            var str = [];
            for (var p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
            return str.join("&");
        },
        exportToExcel: function () {
            // this.$root.preloader = true;
            //Load the catchers
            var request = Object.assign({ apikey: this.$root.apikey }, this.form);

            this.eLoading = true;
            this.preloader = this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/static/ga/export`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        // this.datasource = { total: 0, data: [] };
                        this.eLoading = false;
                        this.preloader = this.$root.preloader = false;
                    } else {
                        //request = this.serialize(resp);
                        //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
                        //this.eLoading = false;
                        // this.preloader = this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
        load: function () {
            this.data = new DataManager({
                url: `${this.$root.serverUrl}/admin/clicks`,
                adaptor: new WebApiAdaptor(),
                crossDomain: true,
                headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            })
        },
        toggleAdvanceFilter() {
            return (this.advanceFilter = !this.advanceFilter);
        },
        History(item) {
            this.modalData = item.data;
        },
        toDate(item) {
            return moment(item).format('MM-DD-YYYY')
        }
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport]
    },
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';

.list-wrapper {
    height: inherit;
    position: relative;
    padding: 0px 12px 0px 18px;
    order-right: 1px solid black;
}

.list-wrapper .text,
.list-wrapper {
    margin: 0;
    padding-bottom: 3px;
    white-space: normal;
}
.e-delete:before{
    content:'\e932';
}


.e-horizontal-listbox .e-list-parent {
    display: inline-flex;
    align-items: center;
}

.e-horizontal-listbox {
    overflow-y: hidden;
    height: 50px;
}

.e-horizontal-listbox .e-list-parent .e-list-item {
    width: max-content;
    line-height: 50px;
    height: 50px;
}
</style>
